@font-face {
  font-family: 'LatoBlack';
  src: url('../public/fonts/Lato-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'LatoBold';
  src: url('../public/fonts/Lato-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'LatoRegular';
  src: url('../public/fonts/Lato-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}


*{
  margin: 0;
  padding: 0;
}

/* Вебкіт-скролбар (Chrome, Safari) */
::-webkit-scrollbar {
  width: 8px;

}

::-webkit-scrollbar-track {
  background: #14101e;
  border: none;
}

::-webkit-scrollbar-thumb {
  background: #33303C;
  border: none;
}

/* Скролбар у Firefox */
::-moz-scrollbar {
  width: 8px;
}

::-moz-scrollbar-track {
  background: transparent;
  border: none;
}

::-moz-scrollbar-thumb {
  background: #33303C;
  border: none;
}

a{
  text-decoration: none;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #14101e;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
