.desktop .top {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 16px;
    margin-bottom: 8px;
  }

  .desktop .top .top-switcher {
    display: flex;
    gap: 10px;
  }

.desktop .top .top-switcher button{
    display: flex;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: none;
    background: rgba(40, 177, 162, 0.15);
    color: #FFF;
    font-family: "LatoRegular", Helvetica;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    cursor: pointer;
}

.desktop .top .top-switcher button.active{
    background: #28B1A2;
}


.desktop .top .top-switcher button:not(.active):hover{
    background: #322f3b;
}

  .setting-svg{
    transition: transform 0.4s ease;
  }

  .setting-svg:hover {
    cursor: pointer;
    transform: rotate(45deg);
    fill: #fff;
  }

  .desktop .search-top-panel {
    height: 36px;
    position: relative;
    width: 810px;
  }
  
  .desktop .rectangle {
    background-color: #262332;
    border: 1px solid;
    border-color: #ccbeff24;
    color: #fff;
    border-radius: 4px;
    height: 36px;
    padding-left: 10px;
    width: 100%;
  }