.sidebar {
    height: 100%;
    width: 50px;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: #262332;
    transition: 0s;
    overflow-x: hidden;
    padding-top: 12px;
    white-space: nowrap;
    transition: all .0.4s ease-in;
  }

  .mySidebar.disabled{
    cursor: not-allowed;
  }

  .mySidebar-item:hover{
    background: rgba(204, 190, 255, 0.14);
  }

  .mySidebar-item-logo{
    padding: 16px 12px!important;
    border-bottom: 1px solid rgba(204, 190, 255, 0.14);
  }
  
  .sidebar a {
    text-decoration: none;
    font-size: 14px;
    color: #818181;
    display: flex;
    justify-content: flex-start;
    color: #FFF;
    font-family: "LatoBold", Helvetica;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding: 12px 12px;
  }

  .sidebar a span{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .sidebar a:hover {
    color: #f1f1f1;
  }
  
  main .sidebar {
    position: absolute;
    top: 0;
    font-size: 36px;
  }

  .sidebar .logo{
    width: 24.828px;
    height: 24px;
  }

  .sidebar.open {
    width: 180px;
    display: flex;
    flex-direction: column;
    padding: 12px 0;
  }
  
  .material-icons,
  .icon-text {
    vertical-align: middle;
  }

  .mySidebar-item.active{
    background: rgba(204, 190, 255, 0.14);
  }

  .icon-text-logo span{
    font-weight: 400;
  }

  .icon-text, .icon-text-logo{
    margin-left: 12px;
  }

  .icon-text-logo{
    color: #FFF;
    font-family: "LatoBlack", Helvetica;
    font-size: 16px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
  }

  .icon-text.hidden, .icon-text-logo.hidden{
    display: none;
    opacity: 0;
  }
  
  .icon-text:not(.hidden), .icon-text-logo:not(.hidden){
    opacity: 1;
    transition: all 3s ease-in-out;
  }
  
  .material-icons {
    padding-bottom: 3px;
    margin-right: 30px;
  }

  
  
  @media screen and (max-height: 450px) {
    .sidebar {
      padding-top: 15px;
    }
    .sidebar a {
      font-size: 18px;
    }
  }
  