.desktop .filter-container {
    width: 200px;
    height: 36px;
    border-radius: 4px;
    background: #C5BFDE;
    position: fixed;
    bottom: 14px;
    left: calc(50% + 50px);
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.desktop .filter-container:hover {
  background: #e6e2f7;
  cursor: pointer;
}

.desktop .filter-container p{
  color: #000;
  font-family: "LatoBold", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}