.desktop {
    background-color: #14101e;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
  }
  
  
  .desktop .right-screen {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0;
    width: calc(99% - 49px);
    margin-bottom: 50px;
  }

  .desktop .right-screen .right-screen_container{
    width: 99%;
    height: 100%;
  } 
  
  
  .desktop .frame {
    align-items: center;
    display: flex;
    gap: 16px;
    left: 0;
    position: absolute;
    top: 16px;
    width: 336px;
  }
  
  .desktop .group {
    height: 36px !important;
    position: relative !important;
    width: 36px !important;
  }
  
  .desktop .text-wrapper {
    color: #b0afb9;
    font-family: "LatoRegular", Helvetica;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    width: fit-content;
  }
  
  .desktop .frame-wrapper {
    
  }
  
  .desktop .frame-2 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 8px;
    position: relative;
  }
  
  .desktop .btn {
    all: unset;
    align-items: center;
    background-color: #7d6cbb;
    border-radius: 8px;
    box-sizing: border-box;
    display: inline-flex;
    flex: 0 0 auto;
    justify-content: center;
    padding: 8px 16px;
    position: relative;
    cursor: pointer;
  }
  
  .desktop .text-wrapper-2 {
    color: #ffffff;
    font-family: "LatoBold", Helvetica;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    width: fit-content;
  }
  
  .desktop .icon-instance-node {
    height: 24px !important;
    position: relative !important;
    width: 24px !important;
  }
  
  .desktop .blockchain-filter {
    align-items: center;
    align-self: stretch;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-color: #ccbeff24;
    display: flex;
    flex: 0 0 auto;
    gap: 4px;
    overflow: hidden;
    padding: 8px 0px;
    position: relative;
    width: 100%;
  }

  .desktop .all-chains {
    align-items: center;
    align-self: stretch;
    background-color: transparent;
    border-color: #ffffff;
    border-left-style: solid;
    border-left-width: 2px;
    border-radius: 0px 8px 8px 0px;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 8px;
    padding: 4px 16px;
    position: relative;
  }

  .desktop .all-chains:hover {
    background: #322f3b;
  }
  
  .desktop .all-chains.active {
    align-items: center;
    align-self: stretch;
    background-color: #008787;
    border-color: #ffffff;
    border-left-style: solid;
    border-left-width: 2px;
    border-radius: 0px 8px 8px 0px;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 8px;
    padding: 4px 16px;
    position: relative;
  }
  
  .desktop .text-wrapper-3 {
    color: #ffffff;
    font-family: "LatoBold", Helvetica;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    width: fit-content;
  }
  
  .desktop .frame-3 {
    align-items: center;
    border-radius: 8px;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 8px;
    padding: 4px 12px;
    position: relative;
  }

  .desktop .frame-3.eth-frame{
    cursor: pointer;
  }

  .desktop .frame-3.eth-frame.active{
    background-color: #008787;
    border-color: #ffffff;
  }

  .desktop .frame-3.eth-frame:hover{
    background: #322f3b;
  }
  
  .desktop .ellipse {
    background-color: #ffc636;
    border-radius: 12px;
    height: 24px;
    position: relative;
    width: 24px;
  }
  
  .desktop .text-wrapper-4 {
    color: #ffffff;
    font-family: "LatoRegular";
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    width: fit-content;
  }

  .desktop .ellipse-2 {
    background-color: #7079b3;
    border-radius: 12px;
    height: 24px;
    position: relative;
    width: 24px;
  }
  
  .desktop .ellipse-3 {
    background-color: #5b9fde;
    border-radius: 12px;
    height: 24px;
    position: relative;
    width: 24px;
  }
  
  .desktop .ellipse-4 {
    background-color: #8247e5;
    border-radius: 12px;
    height: 24px;
    position: relative;
    width: 24px;
  }
  
  .desktop .ellipse-5 {
    background-color: #ff0421;
    border-radius: 12px;
    height: 24px;
    position: relative;
    width: 24px;
  }
  
  .desktop .ellipse-6 {
    background-color: #73cebd;
    border-radius: 12px;
    height: 24px;
    position: relative;
    width: 24px;
  }
  
  .desktop .ellipse-7 {
    background-color: #e84142;
    border-radius: 12px;
    height: 24px;
    position: relative;
    width: 24px;
  }
  
  .desktop .ellipse-8 {
    background-color: #0196fe;
    border-radius: 12px;
    height: 24px;
    position: relative;
    width: 24px;
  }
  
  .desktop .ellipse-9 {
    background-color: #0052ff;
    border-radius: 12px;
    height: 24px;
    position: relative;
    width: 24px;
  }
  
  .desktop .ellipse-10 {
    background-color: #8c8c8c;
    border-radius: 12px;
    height: 24px;
    position: relative;
    width: 24px;
  }
  
  .desktop .ellipse-11 {
    background-color: #dee9df;
    border-radius: 12px;
    height: 24px;
    position: relative;
    width: 24px;
  }
  
  .desktop .frame-4 {
    align-items: center;
    border-radius: 8px;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 8px;
    opacity: 0.5;
    padding: 4px 12px;
    position: relative;
  }
  
  .desktop .icon-chevron-double {
    height: 23px;
    left: 1339px;
    position: absolute;
    top: 12px;
    width: 22px;
  }
  
  .desktop .scroll-bar {
    background-color: #322f3b;
    border-radius: 4px;
    height: 400px;
    left: 1377px;
    position: absolute;
    top: 151px;
    width: 8px;
  }