.desktop .table{
    width: 99%;
    height: 100%;
    border: 1px solid rgba(204, 190, 255, 0.14);
    margin-bottom: 10px;
  }

.scrollable-table-container {
    width: 100%;
  }
  
  .scrollable-table {
    border: 1px solid rgba(204, 190, 255, 0.14);
    border-collapse: collapse;
    width: 100%;
  }
  
  .scrollable-table th, .scrollable-table td {
    color: white;
    padding: 10px;
    border: 1px solid rgba(204, 190, 255, 0.14);
  }
  
  .scrollable-table th {
    position: sticky;
    top: -1px;
    background: #32303C;
    color: #FFF;
    font-family: "LatoBold", Helvetica;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
  }

  .scrollable-table td{
    background: transparent;
  }
  
  
  .td-left, .th-left{
    text-align: left;

  }

  .th-flex{
    display: flex;
    align-items: center;
  }

  .th-flex-right{
    justify-content: flex-end;
  }

  .th-flex-left{
    justify-content: flex-start;
  }

  .td-right, .th-right{
    text-align: right;
  }

  tr:hover{
    background: #322f3b;
    cursor: pointer;
  }

  .token-index{
    color: #84838C;
    font-family: "LatoBold", Helvetica;
    font-size: 14px;
    font-weight: 400;
  }


  .desktop .headers {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 16px;
    padding: 8px 0px;
    position: relative;
    width: 100%;
  }
  
  .desktop .frame-5 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 4px;
    position: relative;
  }
  
  .desktop .div-wrapper {
    align-items: center;
    border-radius: 8px;
    display: inline-flex;
    flex: 0 0 auto;
    padding: 4px 4px 4px 16px;
    position: relative;
  }
  
  .desktop .text-wrapper-5 {
    color: #ffffff;
    font-family: "LatoBold", Helvetica;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  .desktop .frame-6:hover, 
  .desktop .frame-7:hover
  {
    cursor: pointer;
    background: #322f3b;
  }
  
  .desktop .frame-6 {
    align-items: center;
    background-color: #008787;
    border-radius: 8px;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 8px;
    padding: 4px 16px;
    position: relative;
  }
  
  .desktop .frame-7 {
    align-items: center;
    border: 1px solid;
    border-color: #ccbeff24;
    border-radius: 8px;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 8px;
    padding: 4px 16px;
    position: relative;
  }

  .favorite-icon:hover {
    cursor: pointer;
    /* background-color: yellow; */
    fill: yellow;
  }
  

  .pages-section{
    display: flex;
    align-items: center;
    gap: 15px;
    justify-content: flex-end;
    color: #ffffff;
    font-size: 14px;
    margin-right: 12px;
  }

  .pages-section button{
    border: 1px solid #ffffff;
    border-radius: 5px;
    background: transparent;
    color: #ffffff;
    padding: 5px 20px;
    transition: all .2s ease;
  }

  .pages-section button:hover{
    cursor: pointer;
    background: rgba(199, 185, 185, 0.358);
  }


  .loader{
    color: #FFF;
  }