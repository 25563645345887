.desktop .top {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 16px;
    margin-bottom: 8px;
  }
  

  .setting-svg{
    cursor: pointer;
  }

  .desktop .search-top-panel {
    height: 36px;
    position: relative;
    width: 810px;
  }
  
  .desktop .rectangle {
    background-color: #262332;
    border: 1px solid;
    border-color: #ccbeff24;
    color: #fff;
    border-radius: 4px;
    height: 36px;
    padding-left: 10px;
    width: 100%;
  }